<template>
  <v-row>
    <v-dialog v-model="dialogAddCategoria" max-width="500">
      <v-card min-width="360">
        <v-card-title class="text-h5">Nova categoria</v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-text-field dense outlined label="Categoria" v-model="categoria.categoria"></v-text-field>
          <v-select
              class="mb-n5"
              dense
              outlined
              label="Departamento"
              :items="departamentos.lista"
              item-text="departamento"
              item-value="iddepartamento"
              v-model="categoria.iddepartamento"
          ></v-select>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-3">
          <v-spacer />
          <v-btn
              :loading="carregando"
              text
              color="primary"
              elevation="0"
              @click="dialogAddCategoria = false"
          >Cancelar</v-btn>
          <v-btn :loading="carregando" color="primary" elevation="0" @click="addCategoria()">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddSubcategoria" max-width="500">
      <v-card min-width="360">
        <v-card-title class="text-h5">Nova subcategoria</v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-text-field dense outlined label="Subcategoria" v-model="subcategoria.subcategoria"></v-text-field>
          <v-select
              class="mb-n5"
              dense
              outlined
              label="Categoria"
              :items="dados.categorias"
              item-text="categoria"
              item-value="idcategoria"
              v-model="subcategoria.idcategoria"
          ></v-select>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-3">
          <v-spacer />
          <v-btn
              :loading="carregando"
              text
              color="primary"
              elevation="0"
              @click="dialogAddSubcategoria = false"
          >Cancelar</v-btn>
          <v-btn :loading="carregando" color="primary" elevation="0" @click="addSubCategoria()">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeletaCategoria" max-width="500">
      <v-card min-width="360">
        <v-card-title class="text-h5">
          <span class="mx-auto">Exclusão de categoria</span>
        </v-card-title>
        <div class="text-center py-3">
          <v-icon size="100" color="red">mdi-help-circle-outline</v-icon>
        </div>
        <v-card-text class="text-center text-h6">Confirmar exclusão de categoria?</v-card-text>
        <v-card-actions class="pa-3">
          <v-spacer />
          <v-btn
              :loading="carregando"
              text
              color="primary"
              elevation="0"
              @click="dialogDeletaCategoria = false"
          >Cancelar</v-btn>
          <v-btn :loading="carregando" color="primary" elevation="0" @click="deletaCategoria()">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeletaSubcategoria" max-width="500">
      <v-card min-width="360">
        <v-card-title class="text-h5">
          <span class="mx-auto">Exclusão de subcategoria</span>
        </v-card-title>
        <div class="text-center py-3">
          <v-icon size="100" color="red">mdi-help-circle-outline</v-icon>
        </div>
        <v-card-text class="text-center text-h6">Confirmar exclusão de subcategoria?</v-card-text>
        <v-card-actions class="pa-3">
          <v-spacer />
          <v-btn
              :loading="carregando"
              text
              color="primary"
              elevation="0"
              @click="dialogDeletaSubcategoria = false"
          >Cancelar</v-btn>
          <v-btn
              :loading="carregando"
              color="primary"
              elevation="0"
              @click="deletaSubCategoria()"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogErro.status" persistent>
      <CardDialog :dialog="dialogErro" />
    </v-dialog>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Categorias (teste)
          <v-spacer />
          <v-btn fab x-small elevation="0" color="primary" @click="dialogAddCategoria = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-progress-linear indeterminate absolute :active="carregando" />
        <v-card-text>
          <v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
          <v-simple-table v-else>
            <thead>
            <tr>
              <th>ID</th>
              <th>Categoria</th>
              <th>Departamento</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(u,i) in dados.categorias" :key="i">
              <td>{{u.idcategoria}}</td>
              <td>{{u.categoria}}</td>
              <td>{{u.departamento}}</td>
              <td class="text-end">
                <v-btn
                    fab
                    x-small
                    elevation="0"
                    color="error"
                    @click="dialogDeletaCategoria = true, id = u.idcategoria"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-progress-linear indeterminate absolute :active="carregando" />
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Subcategorias
          <v-spacer />
          <v-btn fab x-small elevation="0" color="primary" @click="dialogAddSubcategoria = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
          <v-simple-table v-else>
            <thead>
            <tr>
              <th>ID</th>
              <th>Subcategoria</th>
              <th>Categoria</th>
              <th>Departamento</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(u,i) in dados.subcategorias" :key="i">
              <td>{{u.idsubcategoria}}</td>
              <td>{{u.subcategoria}}</td>
              <td>{{u.categoria}}</td>
              <td>{{u.departamento}}</td>
              <td class="text-end">
                <v-btn
                    fab
                    x-small
                    elevation="0"
                    color="error"
                    @click="dialogDeletaSubcategoria = true, id = u.idsubcategoria"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog.vue";

export default {
  components: { CardDialog },
  name: "ChamadoConfiguracao",
  data: () => ({
    dialogAddCategoria: false,
    dialogAddSubcategoria: false,
    dialogDeletaCategoria: false,
    dialogDeletaSubcategoria: false,
    dialogErro: {
      status: false,
      title: "",
      msg: "",
      icon: "",
      iconColor: "",
    },
    dados: {},
    departamentos: { lista: [] },
    categoria: {},
    subcategoria: {},
    id: 0,
    carregando: false,
    carregandoSkeleton: true,
  }),
  computed: {
    ...mapState(["backendUrl", "pgLimit", "usuario"]),
  },
  methods: {
    listar() {
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}chamado/configuracao/listar`)
          .then((res) => {
            this.dados = res.data;
            this.carregando = false;
            this.carregandoSkeleton = false;
          })
          .catch(() => {
            this.carregando = false;
            this.carregandoSkeleton = false;
          });
    },
    addCategoria() {
      return axios
          .post(
              `${this.backendUrl}chamado/configuracao/categoria/criar`,
              {
                categoria: this.categoria.categoria,
                iddepartamento: this.categoria.iddepartamento,
              }
          )
          .then((res) => {
            if (res.data) {
              this.dialogAddCategoria = false;
              this.dialogErro.title = "Sucesso";
              this.dialogErro.msg =
                  "Categoria adicionada com sucesso!";
              this.dialogErro.icon = "mdi-check-circle-outline";
              this.dialogErro.iconColor = "success";
              this.dialogErro.status = true;
              this.listar();
            } else {
              this.dialogAddCategoria = false;
              this.dialogErro.title = "Erro";
              this.dialogErro.msg =
                  "Não foi possível adicionar a categoria!";
              this.dialogErro.icon = "mdi-alert-circle-outline";
              this.dialogErro.iconColor = "red";
              this.dialogErro.status = true;
            }
          })
          .catch(() => {
            this.dialogAddCategoria = false;
            this.dialogErro.title = "Erro";
            this.dialogErro.msg =
                "Não foi possível adicionar a categoria!";
            this.dialogErro.icon = "mdi-alert-circle-outline";
            this.dialogErro.iconColor = "red";
            this.dialogErro.status = true;
          });
    },
    addSubCategoria() {
      return axios
          .post(
              `${this.backendUrl}chamado/configuracao/subcategoria/criar`,
              {
                subcategoria: this.subcategoria.subcategoria,
                idcategoria: this.subcategoria.idcategoria,
              }
          )
          .then((res) => {
            if (res.data) {
              this.dialogAddSubcategoria = false;
              this.dialogErro.title = "Sucesso";
              this.dialogErro.msg =
                  "Subcategoria adicionada com sucesso!";
              this.dialogErro.icon = "mdi-check-circle-outline";
              this.dialogErro.iconColor = "success";
              this.dialogErro.status = true;
              this.listar();
            } else {
              this.dialogAddSubcategoria = false;
              this.dialogErro.title = "Erro";
              this.dialogErro.msg =
                  "Não foi possível adicionar a subcategoria!";
              this.dialogErro.icon = "mdi-alert-circle-outline";
              this.dialogErro.iconColor = "red";
              this.dialogErro.status = true;
            }
          })
          .catch(() => {
            this.dialogAddSubcategoria = false;
            this.dialogErro.title = "Erro";
            this.dialogErro.msg =
                "Não foi possível adicionar a subcategoria!";
            this.dialogErro.icon = "mdi-alert-circle-outline";
            this.dialogErro.iconColor = "red";
            this.dialogErro.status = true;
          });
    },
    deletaCategoria() {
      return axios
          .post(
              `${this.backendUrl}chamado/configuracao/categoria/excluir`,
              {
                idcategoria: this.id,
              }
          )
          .then((res) => {
            if (res.data) {
              this.dialogDeletaCategoria = false;
              this.dialogErro.title = "Sucesso";
              this.dialogErro.msg = "Categoria deletada com sucesso!";
              this.dialogErro.icon = "mdi-check-circle-outline";
              this.dialogErro.iconColor = "success";
              this.dialogErro.status = true;
              this.listar();
            } else {
              this.dialogDeletaCategoria = false;
              this.dialogErro.title = "Erro";
              this.dialogErro.msg =
                  "Não foi possível deletar a categoria!";
              this.dialogErro.icon = "mdi-alert-circle-outline";
              this.dialogErro.iconColor = "red";
              this.dialogErro.status = true;
            }
          })
          .catch(() => {
            this.dialogDeletaCategoria = false;
            this.dialogErro.title = "Erro";
            this.dialogErro.msg =
                "Não foi possível deletar a categoria!";
            this.dialogErro.icon = "mdi-alert-circle-outline";
            this.dialogErro.iconColor = "red";
            this.dialogErro.status = true;
          });
    },
    deletaSubCategoria() {
      return axios
          .post(
              `${this.backendUrl}chamado/configuracao/subcategoria/excluir`,
              {
                idsubcategoria: this.id,
              }
          )
          .then((res) => {
            if (res.data) {
              this.dialogDeletaSubcategoria = false;
              this.dialogErro.title = "Sucesso";
              this.dialogErro.msg =
                  "Subategoria deletada com sucesso!";
              this.dialogErro.icon = "mdi-check-circle-outline";
              this.dialogErro.iconColor = "success";
              this.dialogErro.status = true;
              this.listar();
            } else {
              this.dialogDeletaSubcategoria = false;
              this.dialogErro.title = "Erro";
              this.dialogErro.msg =
                  "Não foi possível deletar a subcategoria!";
              this.dialogErro.icon = "mdi-alert-circle-outline";
              this.dialogErro.iconColor = "red";
              this.dialogErro.status = true;
            }
          })
          .catch(() => {
            this.dialogDeletaSubcategoria = false;
            this.dialogErro.title = "Erro";
            this.dialogErro.msg =
                "Não foi possível deletar a subcategoria!";
            this.dialogErro.icon = "mdi-alert-circle-outline";
            this.dialogErro.iconColor = "red";
            this.dialogErro.status = true;
          });
    },
    listarDepartamentos() {
      this.departamentos.lista = [];
      return axios
          .post(`${this.backendUrl}departamento/listar`, {
            limit: 9999,
            offset: 0,
            busca: null,
          })
          .then((res) => {
            if (res.data.lista != null) {
              this.departamentos = res.data;
            }
          });
    },
    async init() {
      await this.listar();
      await this.listarDepartamentos();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
</style>
